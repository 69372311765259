import React, { useEffect, useState } from 'react';
import { graphql, navigate } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import ListingsLayout from 'components/layouts/listings/listings.layout';
import { Container, Row, Col } from 'react-bootstrap';
import Button from 'ui-kit/button/button';
import NewsletterCard from 'components/newsletter/card/newsletter-card.component';

const Newsletters = ({ data }: { data: GatsbyTypes.NewslettersDataQuery }) => {
    const { t } = useTranslation();
    const pageLimit = 12;
    const [itemsToShow, setItemsToShow] = useState(pageLimit);
    const newsletters = data.allNewsletters?.nodes;

    // DRX-1865: Temporarily disabling this page. To prepare for future changes to the repository for multisite and to prevent any history,
    // we have decided to keep this page with a redirect.
    useEffect(() => {
        navigate('/404');
    }, []);

    return (
        <ListingsLayout
            eyebrowText={t(`pages.newsletters.eyebrowText`)}
            title={t(`pages.newsletters.title`)}
            subTitle={t(`pages.newsletters.subTitle`)}
        >
            <Container className="mt-3 mt-lg-5">
                <Row xs={1} className="pt-lg-4">
                    {newsletters.slice(0, itemsToShow).map((newsletter, index) => (
                        <Col key={index}>
                            <NewsletterCard
                                title={newsletter.title}
                                eyebrowText={newsletter.field_eyebrow}
                                path={newsletter.relationships?.field_newsletter_pdf?.uri?.url}
                            />
                        </Col>
                    ))}
                </Row>
                {itemsToShow < newsletters.length && (
                    <div className="text-center my-4">
                        <Button
                            label={t(`pages.newsletters.loadMore`)}
                            className={'text-primary'}
                            type="button"
                            variant="text"
                            onClick={() => {
                                setItemsToShow(itemsToShow + pageLimit);
                            }}
                        />
                    </div>
                )}
            </Container>
        </ListingsLayout>
    );
};

export default Newsletters;

export const query = graphql`
    query NewslettersData($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        allNewsletters: allNodeNewsletter(sort: { fields: field_published_date, order: DESC }) {
            nodes {
                title
                field_published_date(formatString: "MMMM DD, YYYY")
                field_eyebrow
                drupal_internal__nid
                relationships {
                    field_newsletter_pdf {
                        id
                        uri {
                            url
                        }
                    }
                }
            }
        }
    }
`;
