import React, { ReactElement } from 'react';

import { Row, Col } from 'react-bootstrap';
import { DRUPAL_BASE_URL } from 'gatsby-env-variables';
import { NewsletterCardProps } from './newsletter-card.props';

import './newsletter-card.style.scss';
import Link from 'ui-kit/link/link';
import DownloadIcon from 'ui-kit/icons/download/download-icon';

const NewsletterCard = ({ title, eyebrowText, path }: NewsletterCardProps): ReactElement => {
    return (
        <Row className="newsletter-card mb-4 pb-3">
            <Col>
                <div className="border p-3 px-lg-5">
                    <div className="newsletter-card__content">
                        <DownloadIcon />
                        <div>
                            {eyebrowText && <div className="text-slate">{eyebrowText}</div>}
                            <Link
                                to={path ? `${DRUPAL_BASE_URL.replace(/\/$/, '')}${path}` : ''}
                                external={false}
                                label={title ? title.toString() : ''}
                                variant="arrow"
                                dataGALocation="Newsletters"
                            />
                        </div>
                    </div>
                </div>
            </Col>
        </Row>
    );
};

export default NewsletterCard;
